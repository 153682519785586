<template>
  <vx-card style="padding-left: 10px">
    <!-- <vs-label style="font-weight: bold"> -->

    <!-- </vs-label> -->
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="subs"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse items-center flex-grow justify-between"
        >
          <div
            class="flex flex-wrap-reverse items-center data-list-btn-container"
          ></div>
          <v-select
            v-model="dataTableParams.limit"
            class="mr-4"
            :options="limitOptions"
            :clearable="false"
          />
        </div>

        <template slot="thead">
          <!-- <vs-th sort-key="email">Email</vs-th> -->
          <vs-th sort-key="purchaseDate">Name</vs-th>
          <!-- <vs-th sort-key="lastName">Last Name</vs-th> -->
          <!-- <vs-th sort-key="status">Status</vs-th> -->
          <!-- <vs-th sort-key="createdAt">Created At</vs-th>
          -->
          <vs-th sort-key="purchaseDate">Start Date</vs-th>
          <vs-th sort-key="expirationDate">End Date</vs-th>
          <vs-th sort-key="purchasePlatform">Store</vs-th>
          <vs-th sort-key="autoRenewStatus">Auto-Renewable</vs-th>
          <vs-th>Status</vs-th>
          <!-- <vs-th sort-key="updatedAt">Updated At</vs-th> -->
          <vs-th sort-key="action" size="25%">Actions</vs-th>
        </template>

<template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <!-- <vs-td :data="data[indextr].email">{{ data[indextr].email }}</vs-td> -->
            <vs-td :data="data[indextr].firstName"
              >{{ data[indextr].firstName }} {{ data[indextr].lastName }}</vs-td
            >
            <!-- <vs-td :data="data[indextr].lastName">{{ data[indextr].lastName }}</vs-td> -->
            <!-- <vs-td :data="data[indextr].status">{{ data[indextr].status }}</vs-td> -->
            <vs-td :data="data[indextr].purchaseDate">{{
              moment(data[indextr].purchaseDate).format(
                "DD/MM/YYYY hh:mm A"
              )
            }}</vs-td>
            <vs-td :data="data[indextr].expirationDate">{{
              moment(data[indextr].expirationDate).format(
                "DD/MM/YYYY hh:mm A"
              )
            }}</vs-td>
            <vs-td :data="data[indextr].purchasePlatform">{{
              data[indextr].purchasePlatform=='apple'?'Apple':'Google'
            }}</vs-td>
            <vs-td :data="data[indextr].autoRenewStatus">{{
              data[indextr].autoRenewStatus ? "Yes" : "No"
            }}</vs-td>

            <vs-td>
              <span class="flex items-center px-2 py-1 rounded">
                <div class="h-3 w-3 rounded-full mr-2" 
                :class='data[indextr].mySubscriptionStatus != "Expired" && data[indextr].mySubscriptionStatus != "Cancelled" && data[indextr].mySubscriptionStatus != "Unsubscribed"?data[indextr].mySubscriptionStatus == "Active"? "bg-success": "bg-warning":"bg-danger"'></div>
                {{data[indextr].mySubscriptionStatus}}
                </span>
             
            </vs-td>
            <!-- <vs-td
              :data="data[indextr].updatedAt"
            >{{ moment(data[indextr].updatedAt).format('DD/MM/YYYY') }}</vs-td>-->
            <vs-td :data="data[indextr]._id">
              <!-- <vs-row vs-type="flex" vs-justify="space-between"> -->
              <vx-tooltip text="Details" position="top" delay=".3s">
                <!-- <vs-button
                  type="gradient"
                  size="small"
                  icon-pack="feather"
                  icon="icon-info"
                  color="success"
                ></vs-button>-->
                <vs-button
                  type="gradient"
                  size="small"
                  icon-pack="feather"
                  @click=" viewDetailHandler(data[indextr]._id)"
                  icon="icon-eye"
                  color="success"
                ></vs-button>
                <!-- <vs-button
                  type="gradient"
                  size="small"
                  icon-pack="feather"
                  @click="googleRestoreSubs(data[indextr],indextr)"
                  icon="icon-info"
                  color="success"
                ></vs-button>-->
                <!-- </vs-row> -->
              </vx-tooltip>
            </vs-td>
          </vs-tr>
        </template>
</vs-table>
<span class="mr-2">{{ currentPage * dataTableParams.limit - (dataTableParams.limit - 1) }}
        -
        {{
          totalDocs - currentPage * dataTableParams.limit > 0
            ? currentPage * dataTableParams.limit
            : totalDocs
        }}
        of {{ totalDocs }}</span
      >
      <vs-pagination
        :total="totalPage"
        v-model="dataTableParams.page"
      ></vs-pagination>
    </div>
  </vx-card>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 10,
      },
      subs: [],
      limitOptions: [5, 10, 25, 50, 100],
    };
  },
  methods: {
    ...mapActions("admin", ["fetchSubscribers"]),
    ...mapActions(["initToFirstPage"]),
    moment(date) {
      return moment(date);
    },
    checkStatus(dateTime) {
      let now = Date.now();
      var date = new Date(dateTime);
      var milliseconds = date.getTime();
      if (now < milliseconds) {
        return true;
      } else {
        return false;
      }
    },
     viewDetailHandler(id) {
      this.$router.push({ name: "jsonView", params: { id: id } });
    },
    getSubscriberList() {
      let self = this;
      this.fetchSubscribers(self.dataTableParams).then((res) => {
        self.subs = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.page = res.data.data.pagination.page;
        self.currentPage = self.page;
      });
    },
    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getSubscriberList();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getSubscriberList();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getSubscriberList();
    },
    // viewDetailHandler(id) {
    //   this.$router.push({ name: "UserDetail", params: { id: id } });
    // },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getSubscriberList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getSubscriberList();
      }
    },
  },
  created() {
    this.initToFirstPage({ pageType: "all"});
    this.getSubscriberList();
  },
};
</script>
